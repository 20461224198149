/* Wrapper container to center the form on the page */
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full viewport height to vertically center */
  margin-top: 50px;
}

/* Center the app logo */
.app-logo {
  display: block;
  margin: 0 auto 10px auto;
  max-width: 45px;
}

/* Align CG logo, input, and button inline */
.wrapper form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Spacing between elements */
  width: 100%; /* Ensure the form spans the container width */
  max-width: 600px; /* Max width to avoid stretching too much */
  padding: 20px;
}

/* CG logo style to keep it inline with input and button */
.wrapper form img {
  max-width: 40px; /* Control the size of the CG logo */
  display: block; /* Ensures it aligns properly */
}

/* Input field styling */
.wrapper form input {
  background-color: var(--color-bg); /* Use background color from index.css */
  color: var(--color-primary); /* Text color from index.css */
  border: 2px solid var(--color-primary-variant); /* Use variant for border */
  font-size: 1.2rem;
  padding: 10px 15px;
  flex: 1; /* Allow the input to grow to take up remaining space */
  border-radius: 0.4rem; /* Consistent border-radius */
  transition: var(--transition); /* Smooth transition */
}

/* Button styling */
.wrapper form button {
  background-color: var(--color-primary); /* Primary color */
  color: var(--color-bg); /* Text color */
  border: 1px solid var(--color-primary); /* Border to match the color */
  font-size: 0.9rem;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 0.4rem;
  transition: var(--transition); /* Use global transition */
}

.wrapper form button:hover {
  background-color: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

/* Response area styling */
.response-area {
  font-size: 1.25rem;
  max-width: 600px;
  text-align: center;
  margin: 20px auto 0;
  color: var(--color-white); /* Match text color */
}

/* Loader animation */
.cg-logo.loading {
  animation: rotation 2s infinite linear;
}

h2 {
  color: var(--color-primary)
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* Footer styling */
.footer {
  font-size: 14px;
  letter-spacing: 4px;
  margin: 50px 0 40px 0;
  opacity: 0.5;
  text-align: center;
  color: var(--color-light);
}
